.Table-ConnectedAppsEndpointList {
  .MuiTable-root {
    .MuiTableHead-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          padding: 0;
          padding-bottom: 16px;
          border-bottom: 1px solid $Color-GrayBorder;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $Color-Gray;
        }
      }
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          border-bottom: none;
          padding: 8px 0;
          padding-bottom: 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $Color-Black;
          &.table-cell-action {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 10px;
          }
        }
      }
    }
  }
}

.Page-BuildPlan {
  .page-content {
    .saved-plans {
      margin-bottom: 52px;
    }
    .product-list {
      margin-bottom: 52px;

      /*   &.product-list-other {
        margin-bottom: 0;
      } */
    }
  }
}

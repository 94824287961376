.Card-Invoice {
  height: 100%;
  background: $Color-White;
  border-radius: 8px;
  border: 1px solid $Color-Primary--90;
  &-Header {
    padding: 16px;
    border-bottom: 1px solid $Color-Primary--90;
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $Color-Gray;
      margin-right: 12px;
    }
    .subtitle {
      font-family: $Font-Secondary;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
  }
  &-Content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 16px;
  }
}

.Card-Invoice-Standart {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 16px;

  .list-item {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 6px;
    border-radius: 12px;
    @include transition-default;

    &:hover {
      background-color: $Color-GrayBackground;
      @include transition-default;
    }
    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $Color-Gray;
      min-width: 200px;
    }
    .value {
      display: flex;
      align-items: center;
      border-left: 1px solid $Color-Primary--90;
      padding-left: 18px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $Black;
      box-sizing: content-box;
      word-break: break-all;
    }
    .extra-value {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $Color-Gray;
      padding-left: 12px;
    }
  }
}

.Card-Invoice-InoviceItems {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 16px;

  .list-item {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 6px;
    border-radius: 12px;
    @include transition-default;

    &:hover {
      background-color: $Color-GrayBackground;
      @include transition-default;
    }
    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $Color-Gray;
      min-width: 200px;
    }
    .value {
      display: flex;
      align-items: center;
      border-left: 1px solid $Color-Primary--90;
      padding-left: 18px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $Black;
      box-sizing: content-box;
    }
    .extra-value {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $Color-Gray;
      padding-left: 12px;
    }
  }
}

.Card-PaymentInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $Color-White;
  border: 1px solid $Color-Primary--90;
  border-radius: 8px;
  padding: 16px;
  height: 100px;
  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $Color-Gray;
    margin-bottom: 8px;
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .text {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: $Black;
    }
  }
}

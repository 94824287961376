.Box-InfoBox {
  border-radius: 8px;
  &.size-small {
    padding: 16px 16px;
  }
  &.size-medium {
    padding: 24px 16px;
  }
  &.size-large {
  }
  &.theme-gray {
    background: $Color-GrayBackground;
    .box-title {
      color: $Color-Navy;
    }
    .box-text {
      color: $Color-Gray;
    }
  }
  &.theme-blue {
    background: $Color-Primary--85;
    .box-title {
      color: $Color-Primary;
    }
    .box-text {
      color: $Color-Gray;
    }
  }

  .box-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .box-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}

.Page-Dashboard {
  .Dashboard {
    .Dashboard-Heading {
      margin-bottom: 32px;
      display: flex;
      flex-direction: column;
      .credit-info {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 8px;
      }
      .welcome {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: $Color-Black;
      }
    }
    .Dashboard-Box-Heading {
      margin-bottom: 16px;
      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: $Color-Black;
      }
      .desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $Color-Gray;
        margin-top: 4px;
      }
      &.has-action {
        display: flex;
        justify-content: space-between;
      }
    }
    .Dashboard-GetStarted {
      margin-bottom: 48px;

      .feature-list {
      }
    }
    .Dashboard-DataUsage {
      margin-bottom: 48px;

      .DataUsageStates {
        background: $Color-White;
        border-radius: 8px;
        padding: 30px 24px;
      }
    }
    .Dashboard-HowUseHirize {
      display: flex;
      flex-direction: column;

      .HowUseHirize-Content {
        background: $Color-White;
        border-radius: 8px;
        padding: 16px;
        .video-title {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: $Color-Navy;
          margin-bottom: 16px;
        }
        .video-content {
          position: relative;
          width: 100%;
          padding-bottom: 56.25%;
          .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
          }
        }
      }
    }
    .Dashboard-ContactUs {
      .ContactUs-Content {
        background: $Color-White;
        border-radius: 8px;
        padding: 16px;
        .contact-list {
          .contact-item {
            &:nth-child(1) {
              border-right: 1px solid $Color-Primary--90;
              border-bottom: 1px solid $Color-Primary--90;
            }
            &:nth-child(2) {
              border-bottom: 1px solid $Color-Primary--90;
            }
            &:nth-child(3) {
              border-right: 1px solid $Color-Primary--90;
            }
            &:nth-child(2n) {
              padding-left: 16px;
            }
            &:nth-child(3),
            &:nth-child(4) {
              .Card-InfoHelper.has-link {
                margin-top: 16px;
              }
            }
          }
        }
      }
    }

    .Dashboard-ConnectList {
      .ConnectList-Content {
        background: $Color-White;
        border-radius: 8px;
        padding: 16px;
        min-height: 248px;
      }
    }
  }
}

.Card-DashboardInfo {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 0px 1px rgba(19, 28, 43, 0.05);
  gap: 16px;
  outline: 1px solid transparent;
  @include transition-default;

  &:hover {
    outline-color: $Color-Primary;
    @include transition-default;
  }
  &.type-contact {
    flex-direction: row;
  }
  &.type-connect {
    flex-direction: row-reverse;
    .info {
      flex: 1;
    }
  }
  .icon {
    display: flex;
    border-radius: 8px;
    border: 1px solid var(--gray-border, #f0f2f5);
    height: 32px;
    width: 32px;
    padding: 6px 8px;
    align-items: center;
    svg {
      width: 18px;
      height: 18px;
      path {
        fill: $Color-Black;
      }
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .text {
      color: $Color-Black;
      font-family: $Font-Secondary;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
    .desc {
      color: $Color-Gray;
      font-family: $Font-Secondary;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.Page-Products {
  &.has-upgrade-now {
    margin-top: 80px;
  }
  .Products {
    .Products-List {
      margin-bottom: 64px;
      .grid-product-list {
        margin-bottom: 64px;
      }
    }
    .Products-Features-List {
      .heading {
        margin-bottom: 16px;
        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: $Color-Black;
        }
        .desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $Color-Gray;
        }
      }
      .grid-product-features-list {
        margin-bottom: 64px;
      }
    }
  }
}

.Card-InfoHelper {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 16px;
  background: $Color-White;
  border-radius: 8px;
  &.has-link {
    @include transition-default;
    &:hover {
      @include transition-default;
      background: $Color-GrayBackground;
    }
  }

  .Card-Header {
    margin-bottom: 12px;
    .card-icon {
      margin-bottom: 12px;
      background: $Color-GrayBackground;
      border-radius: 8px;
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .card-heading {
      .card-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $Color-Navy;
        margin-bottom: 4px;
      }
      .card-desc {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: $Color-Navy;
        height: 32px;
      }
    }
  }
  .Card-Footer {
    .card-actions {
      display: flex;
      justify-content: space-between;
    }
  }
}

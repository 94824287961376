.Layout-Protected {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 32px;
  &.background-gray {
    background-color: $Color-GrayBackground;
  }
  &.background-white {
    background-color: $Color-White;
  }
  &.sidebar-opened {
    .Layout-Page {
      margin-left: calc(220px + 24px);
      width: calc(100% - 220px);
      transition: all 150ms ease-in-out;
    }
  }
  &.loading {
    .MuiAppBar-root,
    .MuiDrawer-paper,
    .Layout-Page {
      filter: blur(4px);
    }
  }
  .Layout-Page {
    margin-left: calc(80px + 24px);
    margin-right: 24px;
    box-sizing: content-box;
    transition: all 150ms ease-in-out;
  }
}

.Page-ConnectedApps {
  .Zapier-Integration-Templates {
    margin-top: 36px;
    .Heading {
      padding-bottom: 12px;
      margin-bottom: 20px;
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: $Color-Navy;
      }
      .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $Color-Gray;
      }
    }
  }
}

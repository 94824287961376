.Page-ForgotPassword {
  height: 100vh;
  background-color: $Color-Navy;
  .ForgotPassword {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-image: url('../../../public/images/bg-forgot-password.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    &-Header {
      display: flex;
      justify-content: center;
      padding-top: 56px;
      .logo {
        img {
          width: 79px;
        }
      }
    }
    &-Content {
      display: flex;
      justify-content: center;
      .Forms {
        background: $Color-White;
        border-radius: 8px;
        width: 600px;
        .Form-ForgotPassword,
        .Form-ResetPassword {
          padding: 44px;

          .heading {
            margin-bottom: 44px;
            .title {
              font-weight: 700;
              font-size: 36px;
              line-height: 40px;
              color: $Color-Navy;
              margin-bottom: 16px;
            }
            .desc {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: $Color-Gray;
            }
          }
        }
        .Message-EmailSent {
          display: flex;
          flex-direction: column;
          padding: 64px 44px;
          .logo {
            margin-bottom: 36px;
            display: flex;
            justify-content: center;
          }
          .title {
            margin-bottom: 16px;
            font-weight: 700;
            font-size: 36px;
            line-height: 40px;
            text-align: center;
            color: $Color-Navy;
          }
          .desc {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $Color-Gray;
            text-align: center;
          }
        }

        .Form-CodeVerification {
          display: flex;
          flex-direction: column;
          padding: 64px 70px;
          .heading {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-bottom: 44px;
            .title {
              font-weight: 700;
              font-size: 36px;
              line-height: 40px;
              text-align: center;
              color: $Color-Navy;
            }
            .desc {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              text-align: center;

              color: $Color-Gray;
              .email {
                font-weight: 600;
                color: $Color-Primary;
              }
            }
          }
          .form-inputs {
            display: flex;
            gap: 22px;
            margin-bottom: 44px;
            .MuiInputBase-root {
              width: 80px;
              height: 80px;
              input {
                padding: 0;
                height: 80px;
                text-align: center;
                font-size: 32px;
              }
            }
          }
          .form-action {
          }
          .form-footer {
            margin-top: 44px;
            .resend-code {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: $Color-Black;
              a {
                color: $Color-Primary;
                font-weight: 700;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    &-Footer {
      padding-bottom: 44px;
      .copyright {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $Color-Primary--90;
      }
    }
  }
}

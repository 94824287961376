.MuiButton-root,
.MuiIconButton-root {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: $Font-Secondary;
  box-shadow: none;
  border-radius: 999px;
  gap: 6px;
  color: $Color-White;
  @include transition-default;
  border: none;
  &:hover {
    border: none;
  }
  .MuiButton-endIcon,
  .MuiButton-startIcon {
    margin-right: 0;
    margin-left: 0;
  }
  .MuiTouchRipple-root {
    display: none;
  }

  &.Mui-disabled {
    background: $Color-GrayBackground !important;
    color: $Color-GrayTint !important;
    box-shadow: none !important;
    border: none;
    svg {
      path {
        fill: $Color-GrayTint !important;
      }
    }
  }

  // VARINT/COLOR
  &.MuiButton-containedPrimary,
  &.MuiIconButton-colorPrimary {
    color: $Color-White;
    background: var(--primary-default, #3a5aff);
    @include Elevation-Primary-Button;
    svg {
      path {
        fill: white;
      }
    }
    &:hover {
      background: var(--primary-20, #2e48cc);
      @include Elevation-Primary-Button;
    }
    &:active {
      background: var(--primary-default, #3a5aff);
      @include Elevation-Primary-Button;
    }
    &:focus,
    &.Mui-focusVisible {
      background: var(--primary-20, #2e48cc);
      @include Elevation-Primary-Button-Focus;
    }
  }

  &.MuiButton-containedSecondary,
  &.MuiIconButton-colorSecondary {
    color: $Color-Black;
    @include Gradient-Secondary-12;
    @include Elevation-Secondary-Button;
    svg {
      path {
        fill: $Color-Black;
      }
    }
    &:hover {
      background: $Color-Secondary-5;
      @include Elevation-Secondary-Button;
    }
    &:active {
      @include Gradient-Secondary-16;
      @include Elevation-Secondary-Button;
    }
    &:focus,
    &.Mui-focusVisible {
      background: $Color-Secondary-5;
      @include Elevation-Secondary-Button-Focus;
    }
  }

  &.MuiButton-outlinedWhite,
  &.MuiIconButton-colorWhite {
    color: var(--neatural-black, #0f0f13);

    @include Gradient-White-Button;
    @include Elevation-White-Button;

    svg {
      path {
        fill: $Color-Gray;
        @include transition-default;
      }
    }
    &:hover {
      @include Gradient-White-Button-Hover;
      @include Elevation-White-Button;
      color: $Color-Black;
      svg {
        path {
          fill: $Color-Black;
          @include transition-default;
        }
      }
    }
    &:active {
      @include Gradient-White-Button-Pressed;
      @include Elevation-White-Button;
      color: $Color-Black;
      svg {
        path {
          fill: $Color-Black;
          @include transition-default;
        }
      }
    }
    &:focus,
    &.Mui-focusVisible {
      @include Gradient-White-Button;
      @include Elevation-White-Button-Focus;
      color: $Color-Black;
      svg {
        path {
          fill: $Color-Black;
          @include transition-default;
        }
      }
    }
  }

  &.MuiButton-outlinedRed,
  &.MuiIconButton-colorRed-outlined {
    color: $Color-Error;
    @include Gradient-White-Button;
    @include Elevation-White-Button;

    svg {
      path {
        fill: $Color-Error;
        @include transition-default;
      }
    }
    &:hover {
      @include Gradient-White-Button-Hover;
      @include Elevation-White-Button;
    }
    &:active {
      @include Gradient-White-Button-Pressed;
      @include Elevation-White-Button;
    }
    &:focus,
    &.Mui-focusVisible {
      @include Gradient-White-Button-Pressed;
      @include Elevation-Error-Button-Focus;
    }
  }

  &.MuiButton-containedRed,
  &.MuiIconButton-colorRed {
    color: $Color-White;
    @include Gradient-Error;
    @include Elevation-Error-Button;
    svg {
      path {
        fill: $Color-White;
        @include transition-default;
      }
    }
    &:hover {
      background: var(--accent-error-default, #df1c3f);
      @include Elevation-Error-Button;
    }
    &:active {
      @include Gradient-Error-Hover;
      @include Elevation-Error-Button;
    }
    &:focus,
    &.Mui-focusVisible {
      background: var(--accent-error-10, #c91939);
      @include Elevation-Error-Background-Focus;
    }
  }

  &.MuiButton-outlinedTransparent,
  &.MuiIconButton-colorTransparent {
    color: $Color-Black;
    background: var(--gray-background, #f6f8fa);
    svg {
      path {
        fill: $Color-Black;
        @include transition-default;
      }
    }
    &:hover {
      background: var(--gray-border, #f0f2f5);
    }
    &:active {
      @include Gradient-White-Button;
      @include Elevation-White-Button-Focus;
    }
    &:focus,
    &.Mui-focusVisible {
      background: $Color-GrayBorder;
    }
  }

  &.MuiButton-containedWhite {
    color: $Color-White;
    outline: 1px solid rgba($Color-White, 0.08);
    background: rgba($Color-White, 0.1);
    svg {
      path {
        fill: $Color-White;
        @include transition-default;
      }
    }
    &:hover {
      outline: 1px solid rgba($Color-White, 0.08);
      background: rgba($Color-White, 0.15);
      box-shadow: none;
    }
    &:active {
      border: 1px solid rgba($Color-White, 0.08);
      background: rgba($Color-White, 0.15);
      box-shadow: 0px 0px 0px 1px rgba($Color-White, 0.08);
    }
    &:focus,
    &.Mui-focusVisible {
      outline: 1px solid rgba($Color-White, 0.08);
      background: rgba($Color-White, 0.1);
      box-shadow: 0px 0px 0px 1px rgba($Color-White, 0.08);
    }
  }
}

// BUTTON SIZE
.MuiButtonBase-root {
  &.MuiButton-sizeXsmall,
  &.MuiIconButton-sizeXsmall {
    padding: 6px 12px;
    height: 24px;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.MuiButton-sizeSmall,
  &.MuiIconButton-sizeSmall {
    padding: 6px 12px;
    height: 32px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  &.MuiButton-sizeMedium,
  &.MuiIconButton-sizeMedium {
    padding: 10px 12px;
    height: 40px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &.MuiButton-sizeLarge,
  &.MuiIconButton-sizeLarge {
    padding: 12px 16px;
    height: 44px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

// ICONBUTTON SIZE
.MuiIconButton-root {
  &.MuiIconButton-sizeSmall {
    padding: 10px;
    padding: 0;
    height: 32px;
    width: 32px;
  }
  &.MuiIconButton-sizeMedium {
    padding: 16px;
    padding: 0;
    height: 40px;
    width: 40px;
  }
  &.MuiIconButton-sizeLarge {
    padding: 16px;
    padding: 0;
    height: 44px;
    width: 44px;
  }
}

.MuiButton-root {
  &.icon-right {
    .MuiButton-endIcon {
      position: absolute;
      right: 16px;
    }
  }
}

.MuiButton-root {
  &.anim-icon {
    .MuiButton-endIcon {
      transition: transform 300ms ease-in-out;
    }
    &:hover {
      .MuiButton-endIcon {
        transform: translateX(6px);
        transition: transform 300ms ease-in-out;
      }
    }
  }
}

.MuiLoadingButton-root {
  &.MuiLoadingButton-loading {
    color: transparent !important;
    .MuiCircularProgress-root {
      width: 20px !important;
      height: 20px !important;
    }
    .MuiButton-endIcon,
    .MuiButton-startIcon {
      opacity: 0;
    }
  }
  .MuiLoadingButton-loadingIndicator {
    svg {
      animation: animation-loader 1.4s linear infinite;
    }
  }
}

@keyframes animation-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.MuiButtonBase-root {
  &:active {
    box-shadow: none;
  }
  &.Mui-focusVisible {
    box-shadow: none;
  }
}

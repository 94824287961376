.TableEmpty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 92px;
  margin-bottom: 92px;
  .icon {
    margin-bottom: 32px;
    svg {
      width: 80px;
      height: 80px;

      path {
        fill: $Color-GrayTint;
      }
    }
  }
  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $Color-Black;
    margin-bottom: 8px;
  }
  .desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $Color-Gray;
  }
  &-Content {
    margin-top: 32px;
  }
}

.Chart-DataUsageChart {
  .recharts-surface {
    .recharts-bar {
      &:nth-last-of-type(1) {
        .recharts-bar-rectangles {
          .recharts-bar-rectangle {
            .recharts-rectangle {
              stroke: none;
            }
          }
        }
      }
      .recharts-bar-rectangles {
        .recharts-bar-rectangle {
          .recharts-rectangle {
            stroke: $Color-White;
            &.size-medium {
              stroke-dasharray: 32, 100%;
            }
            &.size-large {
              stroke-dasharray: 48, 100%;
            }
          }
        }
      }
    }
    .recharts-xAxis {
    }
  }
  .recharts-cartesian-axis {
    &.recharts-xAxis {
      .recharts-cartesian-axis-tick {
        text {
          tspan {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            fill: $Color-Navy;
          }
        }
      }
    }
    &.recharts-yAxis {
      .recharts-cartesian-axis-tick {
        text {
          tspan {
            font-size: 14px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            fill: $Color-GrayTint;
          }
        }
      }
    }
  }
  .recharts-cartesian-axis {
    &-line {
      stroke: $Color-Primary--90;
    }
  }
}

.recharts-tooltip-wrapper {
  border: none !important;
  outline: none !important;
}
.RechartTooltip-DataUsage {
  background: $Color-Black;
  border-radius: 8px;
  padding: 16px;
  border: none;
  color: $Color-White;
  .date-info {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .date-weekly {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: $Color-White;
    }
    .date-1 {
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      color: $Color-White;
    }
    .date-2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: $Color-White;
    }
  }
  .data-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    li {
      display: flex;
      .label {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $Color-White;
        margin-right: 6px;
      }
      .value {
        font-weight: 400;
        font-size: 16px;
        line-height: 14px;
        color: $Color-White;
      }
    }
  }
}

.react-checkbox-tree {
  ol {
    li.rct-node.rct-node-parent {
      &.rct-node-collapsed {
        .rct-text {
          .rct-collapse {
            &.rct-collapse-btn {
              transform: rotate(-90deg);
              @include transition-default;
            }
          }
        }
      }
      .rct-text {
        .rct-collapse {
          &.rct-collapse-btn {
            transform: rotate(0);
            padding: 0;
            @include transition-default;

            svg {
              width: 24px;
              height: 24px;
              path {
                fill: $Color-Gray;
              }
            }
          }
        }
        label {
          display: flex;
          align-items: center;

          .rct-checkbox {
            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}

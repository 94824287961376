.DropzoneStandart {
  background: $Color-White;
  border: 1px solid $Color-GrayBorder;
  border-radius: 8px;
  padding: 24px;
  cursor: pointer;
  &.has-file {
    border: none;
    padding-left: 0;
  }
  .dropzone-front {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .icon {
    }

    .text-1,
    .text-top {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $Color-Gray;
      margin-bottom: 4px;
      strong {
        font-weight: 600;
        color: $Color-Primary-20;
      }
    }
    .text-2 {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $Color-Gray;
    }
    .text-top {
      color: $Color-Primary-20;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .dropdoze-uploaded-file {
    display: flex;
    gap: 16px;
    .icon {
    }
    .file-detail {
      width: 100%;
      display: flex;
      .file-name {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $Black;
        margin-right: 8px;
      }
      .file-size {
        display: flex;
        align-items: center;
        width: 100px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $Color-Gray;
      }
      .file-action {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
      .file-progress {
        display: flex;
        align-items: center;
        margin-top: 10px;
        gap: 12px;
        border-radius: 4px;

        .MuiLinearProgress-root {
          width: 100%;
          border: none;
          height: 8px;
          .MuiLinearProgress-bar {
            background: $Color-Primary-20;
            border-radius: 4px;
          }
        }
        .label {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $Black;
        }
      }
    }
  }
}

.Page-Analytics {
  .Analytics {
    .Analytics-DataUsage {
      padding: 24px;
      background: $Color-White;
      margin-bottom: 24px;
      border-radius: 8px;
    }
    .Analytics-DataUsageDetail {
      .DataUsageDetail {
        padding: 24px;
        background: $Color-White;
        border-radius: 8px;

        .UsageDetail-Table-Header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 26px;
          .table-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: $Color-Black;
          }
          .table-action {
          }
        }
        .UsageDetail-Table {
          .MuiTableCell-root {
            height: 45px;
          }
        }
      }
    }
  }
}

.DataUsageChart {
  &-Heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: $Color-Black;
    }
    .product-bar-list {
      display: flex;
      gap: 24px;
      .bar-item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        .bar-color {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: $Color-White;
        }
        .bar-text {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $Color-Navy;
        }
      }
    }
    .action {
    }
  }
}

@mixin default-before-after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin transition-default {
  transition: all 200ms ease-in-out;
}

$Color-Primary: #3a5aff;
$Color-Primary-20: #2e48cc;
$Color-Primary--80: #d8deff;
$Color-Primary--85: #e2e6ff;
$Color-Primary--90: #ebefff;

$Color-Secondary: #82ff57;
$Color-Secondary-5: #7cf253;
$Color-Secondary--80: #e6ffdd;
$Color-Secondary--85: #ecffe6;
$Color-Secondary--90: #f2ffee;

// Neatural
$Black: black;
$Color-Black: #0f0f13;
$Color-White: #ffffff;
$Color-Navy: #131c2b;
$Color-Gray: #818898;
$Color-GrayTint: #a4acb9;
$Color-GrayBackground: #f6f8fa;
$Color-GrayBorder: #f0f2f5;

$Color-Error: #df1c3f;
$Color-Error-10: #c91939;
$Color-ErrorTint: #fce8ec;
$Color-Success: #40c4aa;
$Color-Success-40: #267666;
$Color-SuccessTint: #d9f3ee;
$Color-Warning: #ffa24c;
$Color-Warning-40: #99612e;
$Color-WarningTint: #ffecdb;
$Color-Violet: #df1ca8;
$Color-Violet-40: #861165;
$Color-VioletTint: #f9d2ee;
$Color-Purple: #5f57ff;
$Color-Purple-40: #393499;
$Color-PurpleTint: #dfddff;
$Color-Yellow: #ffbe4c;
$Color-Yellow-40: #99722e;
$Color-YellowTint: #fff2db;
$Color-Red: #df1c28;
$Color-Red-40: #861118;
$Color-RedTint: #f9d2d4;
$Color-Blue: #33cfff;
$Color-Blue-40: #1f7c99;
$Color-BlueTint: #d6f5ff;
$Color-Ocean: #33e7ff;
$Color-Ocean-40: #1f8b99;
$Color-OceanTint: #d6faff;

// FONTS
$Font-Primary: 'Eudoxus Sans', system-ui, sans-serif;
$Font-Secondary: 'Inter', sans-serif;

.Modal-EmailVerification {
  .Modal-Box {
    width: 480px;
    padding: 24px;

    .Modal-Header {
      .icon {
        svg {
          width: 24px;
          height: 24px;
          path {
            fill: $Color-Primary;
          }
        }
      }
    }
    .Modal-Content {
    }
    .Modal-Footer {
    }
  }
}

.Page-SourceSetup {
  .SourceSetup {
    &-Form {
      background: $Color-White;
      border: 1px solid $Color-Primary--90;
      border-radius: 8px;
      padding: 24px;
      min-height: 400px;
      .form-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        &-start {
          .source-info {
            display: flex;
            align-items: center;
            .source-icon {
              margin-right: 16px;
              svg {
                height: 55px;
                width: 55px;
              }
            }
            .source-detail {
              display: flex;
              flex-direction: column;
              .source-name {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: $Color-Navy;
              }
            }
          }
        }
        &-end {
          display: flex;
          flex-direction: column;
          .authorize-action {
            display: flex;
            justify-content: flex-end;
          }
          .authorize-message {
            margin-top: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $Color-Gray;
          }
        }
      }
      .form-body {
        display: flex;
        flex-direction: column;
      }
      .form-footer {
        margin-top: 64px;
        padding-top: 24px;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid $Color-Primary--90;
      }
    }
  }
}

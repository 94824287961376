.Page-EndPoints {
  .EndPoints {
    .EndPoints-List {
      padding: 24px;
      background: $Color-White;
      .EndPoints-Table {
        @include for-tablet-down {
          display: flex;
          flex-direction: column;
        }
        .table-cell-action {
          margin-left: -16px;
          display: flex;
          gap: 12px;
        }
      }
    }
  }
}

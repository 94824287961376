.Page-Verification {
  background-color: $Color-Primary;
  width: 100vw;
  height: 100vh;
  background: var(
    --gradient-primary-button-gradient-16,
    linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%),
    #3a5aff
  );
}

.Modal-CreateEvaluator {
  .Modal-Box {
    width: 440px;
    padding: 24px;
    .Modal-Header {
    }
    .Modal-Content {
      .Evaluator-Name {
        margin-top: 12px;
        margin-bottom: 32px;
      }
    }
    .Modal-Footer {
      display: flex;
      flex-direction: row;
      gap: 24px;
      .button-cancel {
        width: 128px;
      }
    }
  }
}

.Modal-DialogConfirm {
  &.Modal-Container {
    .Modal-Box {
      width: 400px;
      padding: 24px;

      .Modal-Header {
        .icon {
          border: none;
          background-color: transparent;
          margin-bottom: 20px;
        }
      }
      .Modal-Content {
        .icon-credit-card-icon {
          width: 34px;
        }
      }
      .Modal-Footer {
        Button {
          &.buton-dialog-type-warning {
            /* background: $Color-Warning;
            @include transition-default;
            &:hover {
              background: rgba($Color-Warning,  0.7);
              @include transition-default;
            } */
          }
          &.buton-dialog-type-alert {
            background: $Color-Error;
            @include transition-default;
            &:hover {
              background: rgba($Color-Error, 0.7);
              @include transition-default;
            }
          }
        }
      }
    }
  }
}

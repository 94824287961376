.Page-ChatCV {
  .ChatCV {
    display: flex;
  }

  .ChatCv-Sidebar {
    max-width: 320px;
    min-width: 320px;
    background-color: $Color-Primary;
    height: 100vh;
    display: flex;
    flex-direction: column;
    &-Heading {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 12px;

      .logo {
        display: flex;
        align-items: center;
        img {
          width: 48px;
        }
      }

      .product-name {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: $Color-Primary--90;
        padding-left: 12px;
        margin-left: 12px;
        border-left: 1px solid $Color-Primary--90;
      }
    }
    &-Content {
      padding: 12px;
      display: flex;
      flex-direction: column;
      .cv-dropzone {
        display: flex;
        width: 100%;
        .DropzoneStandart {
          width: 100%;
        }
      }

      .chat-list {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .chat-list-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $Color-Primary--90;
        }
      }
      .no-chat {
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        .icon {
          margin-bottom: 12px;
          svg {
            width: 48px;
            height: 48px;
            path {
              fill: $Color-Primary--90;
            }
          }
        }
        .text {
          color: $Color-Primary--90;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    &-Footer {
      margin-top: auto;
      padding: 24px 12px;
      display: flex;
      justify-content: center;
    }
  }

  .ChatArea {
    background-color: $Color-Primary--90;
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    width: 100% !important;
    display: flex;
    flex-direction: column;
    &-Heading {
      box-sizing: content-box;
      padding: 6px 24px;
      border-bottom: 1px solid $Color-Primary--80;
      position: sticky;
      top: 0;
      display: flex;
      justify-content: space-between;
      .cv-profile {
        display: flex;
        align-items: center;
        gap: 12px;
        .name {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $Color-Navy;
        }
      }
      .actions {
        display: flex;
        gap: 12px;
      }
    }
    &-Content {
      flex-grow: 1;
      padding: 24px;
      padding-bottom: 0;
      max-height: calc(100vh - (53px + 99px));
      .message-list {
        overflow-y: scroll;
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-right: 24px;
        &::-webkit-scrollbar {
          width: 3px;
          height: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $Color-Primary--80;
        }
        &::-webkit-scrollbar-track {
          background-color: $Color-Primary--90;
        }
        & > li {
          margin-bottom: 24px;
        }
      }
    }
    &-Footer {
      box-sizing: content-box;
      height: 50px;
      padding: 24px;
      display: flex;
      border-top: 1px solid $Color-Primary--80;
      position: relative;
      .message-actions {
        position: absolute;
        left: 0;
        top: -30px;
        left: 50%;
        transform: translateY(-50%);
      }
      form {
        display: flex;
        width: 100%;
        gap: 24px;
        button {
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .ChatMessage {
    width: 100%;
    display: flex;
    gap: 24px;
    align-items: flex-end;
    &.ai {
      flex-direction: row;
      .ChatMessage-Body {
        background-color: $Color-Primary-20;
        .message-content {
          .message-text,
          .message-date {
            color: $Color-White;
          }
          .message-text {
            .finish {
              display: none;
            }
          }
        }
      }
    }

    &.user {
      flex-direction: row-reverse;
      .ChatMessage-Body {
        background-color: $Color-Primary--80;
        .message-content {
          .message-text,
          .message-date {
            color: $Color-Black;
          }
        }
      }
    }
    .ChatMessage-Body {
      max-width: 40%;
      min-width: 200px;
      border-radius: 12px;
      padding: 12px;
      .message-content {
        .message-text,
        .message-date {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        .message-date {
          padding-top: 12px;
          color: $Color-Navy;
          font-size: 12px;
          font-weight: 500;
          text-align: right;
        }
      }
    }
  }

  .ChatListItem {
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: transparent;
    border-radius: 12px;
    padding: 6px;
    cursor: pointer;
    user-select: none;
    height: 56px;
    @include transition-default;
    &:hover,
    &.active {
      background-color: $Color-Primary-20;
      @include transition-default;
    }
    .icon {
      display: flex;
      align-items: center;
      svg {
        path {
          fill: $Color-Primary--90;
        }
      }
    }
    .name {
      color: $Color-Primary--90;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      width: 140px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    .actions {
      display: flex;
      gap: 8px;
      margin-left: auto;
    }
  }
}

@mixin for-small-phone-only {
  @media (max-width: 560px) {
    @content;
  }
}

@mixin for-sm-phone-only {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin for-phone-only {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin between-phone-and-tablet {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin for-tablet-down {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin between-tablet-and-desktop {
  @media (min-width: 1024px) and (max-width: 1400px) {
    @content;
  }
}

@mixin for-desktop {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

.Card-ConnectApp {
  background: $Color-White;
  border: 1px solid $Color-Primary--90;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .Card-Start {
    padding: 32px 24px 16px 24px;
    .card-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      &-left {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .card-title {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: $Color-Black;
        }
        .card-link {
          display: flex;
        }
      }

      &-right {
        .card-icon {
          svg {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    .card-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $Color-Gray;
      padding-right: 25%;
      min-height: 80px;
    }
  }
  .Card-Middle {
    padding: 0;
    background: $Color-GrayBackground;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 600ms ease-in-out;
    padding: 0 20px;

    &.opened {
      max-height: 1000px;
    }
  }
  .Card-End {
    border-top: 1px solid $Color-Primary--90;
    padding-top: 12px;
    padding: 12px 24px 16px 24px;

    .card-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .actions-left {
        display: flex;
        align-items: center;
        gap: 8px;
        .not-avaible {
          border-right: 1px solid $Color-GrayBorder;
          margin-right: 8px;
          padding-right: 16px;
        }
      }
      .actions-right {
        .MuiButton-root {
          .MuiButton-endIcon {
            svg {
              transform: rotate(0);
              @include transition-default;
            }
          }
          &.opened {
            .MuiButton-endIcon {
              svg {
                transform: rotate(180deg);
                @include transition-default;
              }
            }
          }
        }
      }
    }
    .zapier-action {
      display: flex;
      margin-top: 12px;
      gap: 8px;
    }
    .card-actions-webhook {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}

.Card-ConnectApp-ApiKey {
  margin: 12px 0;
  .card-endpoint-list {
    padding: 0;
    background: $Color-GrayBackground;
    .list-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      padding-left: 12px;
      padding-right: 12px;
      border-bottom: 1px solid $Color-GrayBorder;
      margin-bottom: 12px;
      .title-detail,
      .title-action {
        width: 80%;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $Color-Gray;
      }
      .title-action {
        text-align: right;
        width: 20%;
      }
    }
    .list-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 16px;
        padding: 4px 12px;
        @include transition-default;
        gap: 12px;
        &:hover {
          @include transition-default;
          background: rgba($color: $Color-GrayTint, $alpha: 0.15);
        }
        .item-detail {
          width: 80%;
          display: flex;
          flex-direction: column;
          gap: 8px;
          .item-key,
          .item-token {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            small {
              color: $Color-Primary;
              font-size: 11px;
              font-weight: bold;
              min-width: 48px;
            }
            .value {
              font-weight: 400;
              font-size: 11px;
              line-height: 20px;
              color: $Color-Navy;
              text-wrap: wrap;
              max-width: 100%;
              line-break: anywhere;
            }
          }
        }

        .item-action {
          display: flex;
          justify-content: flex-end;
          width: 20%;
          gap: 4px;
        }
      }
      .list-item-newkey {
        .item-input {
          width: 50%;
        }
        .item-new-action {
          width: 50%;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.Card-ConnectApp-Webhook {
  margin: 20px 0;

  display: flex;
  flex-direction: column;
  .form-webhook-connect {
    display: flex;
    gap: 12px;
    .input {
      flex: 1;
    }
  }
  .test-connect {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
}

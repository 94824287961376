.Card-SavedPlan {
  background: $Color-White;
  border: 1px solid $Color-Primary--90;
  border-radius: 8px;
  &.opened {
    .SavedPlan-Content {
      visibility: visible;
      max-height: 500px;
      transition: all 200ms ease-in-out;
      &-Inner {
      }
    }
  }
  .SavedPlan-Header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    .Header-Start {
      display: flex;
      align-items: center;
      gap: 16px;
      .action-toggle {
        margin: 0 8px;
      }
      .product-info {
        display: flex;
        align-items: center;
        gap: 16px;
        .price-value {
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
          color: $Color-Navy;
        }
        .payment-plan {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $Color-Gray;
        }
      }
      .credit-info {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $Color-Gray;
      }
    }
    .Header-End {
      display: flex;
      .MuiButtonBase-root {
        margin-left: 16px;
        &.MuiIconButton-root {
          svg {
            path {
              fill: $Color-Gray;
            }
          }
        }
      }
    }
  }
  .SavedPlan-Content {
    visibility: hidden;
    max-height: 0;
    transition: all 200ms ease-in-out;

    &-Inner {
      background: $Color-GrayBackground;
      display: flex;
      flex-wrap: wrap;
      padding: 24px;
      padding-bottom: 16px;
    }
    .product-detail-item {
      width: 50%;
      margin-bottom: 8px;
      &:nth-child(2n) {
        padding-left: 40px;
      }
      &:nth-child(2n + 1) {
        padding-right: 40px;
      }
    }
  }
}

.Page-Checkout {
  .Checkout {
    .Checkout-Header {
      border-bottom: 1px solid $Color-Primary--90;
      padding-bottom: 24px;
      .Header-Container {
        display: flex;
        justify-content: space-between;
        padding-top: 16px;
        padding-bottom: 16px;
      }
      .checkout-steps {
        display: flex;
        align-items: center;
      }
      .logo {
        width: 56px;
        height: 56px;
        background: $Color-Navy;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 32px;
        }
      }
    }
    .Checkout-Content {
      margin-top: 30px;
      .Checkout-Outlet {
        .page-heading {
          margin-bottom: 44px;
          .title {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: $Color-Navy;
          }
        }
        .section-title {
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
          color: $Color-Navy;
          margin-bottom: 16px;
        }
      }
      .Checkout-PricingTable {
        margin-top: 54px;
        .switch-pricing-plan {
          position: relative;
          .annual-discount {
            position: absolute;
            right: 0;
            /* right: -12px; */
            top: -36px;
          }
        }
        .pricing-table {
          background: $Color-GrayBackground;
          border-radius: 8px;
          padding: 24px 16px;
          margin-top: 18px;
          .pricing-total {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $Color-GrayBorder;
            padding-bottom: 8px;
            .total-label {
              font-weight: 700;
              font-size: 18px;
              line-height: 28px;
              color: $Color-Navy;
              width: 50%;
              display: flex;
              align-items: center;
            }
            .total-info {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .price-value {
                display: flex;
                align-items: center;
                .price {
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 28px;
                  color: $Color-Navy;
                }
                .billing-plan {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: $Color-Gray;
                }
              }
              .credit-info {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: $Color-Gray;
              }
            }
          }
          .pricing-list {
            display: flex;
            flex-direction: column;
            margin-top: 18px;
            gap: 16px;
            .pricing-item {
            }
          }
          .checkout-info {
            margin-top: 24px;
          }
          .checkout-action {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            .button-save-later {
              padding: 8px 15px;
              font-weight: 700;
              color: $Color-Gray;
            }
          }
        }
        .stripe-area {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-top: 20px;
          background: $Color-GrayBackground;
          border-radius: 8px;
          padding: 8px 25px;

          .text {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $Color-Gray;
            @include for-desktop {
              font-size: 10px;
            }
          }
          .stripe-icon {
            display: flex;
            align-items: center;
            margin-left: auto;
            svg {
              height: 22px;
              width: 100px;
            }
          }
        }
        .checkout-message {
          margin-top: 24px;
        }
      }
    }
  }
}

.Stepper-Checkout {
  .stepper-list {
    display: flex;
    margin-right: 12px;
    gap: 12px;
    .stepper-item {
      display: flex;
      align-items: center;

      &.next {
        opacity: 0.5;
      }
      .stepper-number {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $Color-GrayBorder;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $Color-Black;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
      }
      .stepper-label {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $Color-Black;
        margin-right: 12px;
      }
      .stepper-arrow {
        display: flex;
        align-items: center;
      }
    }
  }
}

.Card-CreditCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 72px;
  background: rgba($Color-White, 0.3);
  border: 1px solid $Color-GrayBorder;
  border-radius: 8px;
  padding: 12px;

  .card-body {
    display: flex;
    align-items: center;
    .card-logo {
      margin-right: 12px;
      padding-right: 12px;
      border-right: 1px solid $Color-GrayBorder;
      display: flex;
      align-items: center;
      svg {
        width: 46px;
        height: 32px;
      }
    }
    .card-info {
      display: flex;
      flex-direction: column;

      .card-no {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $Color-Navy;
        margin-bottom: 4px;
      }
      .card-expired-date {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $Color-Gray;
      }
    }
    .card-action {
      margin-left: auto;
    }
  }
  .card-footer {
    margin-top: 12px;
  }
}

.Layout-Public {
  height: 100vh;
  display: flex;
  .Layout-Start {
    width: 44%;
    margin-left: 108px;
    margin-right: 128px;
  }
  .Layout-End {
    width: 56%;
    background-image: url('../../../../public/images/bg-login.svg');
    background-color: $Color-GrayBorder;
    background-repeat: repeat-y;
    background-position: center center;
    background-size: cover;
    @include for-desktop {
      background-position: center bottom;
      background-size: 50vw;
    }
    &-Header {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding-top: 40px;
      height: 100%;
      .logo {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 44px;
        margin-right: 108px;
        img {
          width: 100px;
        }
      }
      .text-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 110px;
        padding-right: 24px;
        .text {
          color: $Color-Black;
          font-family: $Font-Secondary;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px;
        }
        .author {
          display: flex;
          align-items: center;
          margin-top: 48px;
          &-name {
            color: $Color-Black;
            font-family: $Font-Secondary;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
          &-title {
            border-left: 1px solid rgba($color: $Color-Navy, $alpha: 0.5);
            padding-left: 6px;
            margin-left: 6px;
            color: rgba($color: $Color-Navy, $alpha: 0.5);
            font-family: $Font-Secondary;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
      .cover-image {
        margin-top: auto;
        img {
          max-width: 100%;
          min-width: 100%;
          padding-left: 100px;
        }
      }
    }
  }
}

.Modal-AddTagsFiles {
  .Modal-Box {
    width: 480px;
    padding: 24px;

    .Modal-Header {
    }
    .Modal-Content {
      .icon-credit-card-icon {
        width: 34px;
      }
    }
    .Modal-Footer {
    }
  }
}

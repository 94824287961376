.Card-BasicInfo {
  background: rgba($Color-White, 0.3);
  border: 1px solid $Color-Primary--90;
  border-radius: 8px;

  padding: 16px;
  .card-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $Black;
    margin-bottom: 12px;
  }
  .card-desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $Color-Black;
  }
}

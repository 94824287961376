.Page-Parser {
  .Parser-Content {
    background: $Color-White;
    border: 1px solid $Color-Primary--90;
    border-radius: 8px;
    padding: 24px;
    .Parser-Tabs {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $Color-GrayBorder;
      margin-bottom: 27px !important;
      .parser-content-tab-action {
        padding-bottom: 18px;
        display: flex;
        gap: 16px;
      }
    }
    .Tabs-Parser {
      .MuiTabs-scroller {
      }
      .parser-content-tab-action {
        margin-left: auto;
      }
    }
    .Parser-Header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      &-Left {
        .parsed-info {
          display: flex;
          .text {
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: $Color-Navy;
            margin-right: 4px;
          }
          .value {
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: $Color-Primary-20;
          }
        }
      }
      &-Right {
        display: flex;
        gap: 16px;
        .filters {
          .MuiBadge-badge {
            background-color: $Color-Primary-20;
            top: -9px;
            right: -9px;
          }
        }
        .add-parser {
        }
      }
    }
    .Parser-Table {
      .TableActions {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
      }
      .MuiTableRow-root {
        &.MuiTableRow-hover {
          &:hover {
            background: $Color-GrayBackground;
            .TableActions {
              opacity: 1;
              visibility: visible;
            }
          }
        }
        &.Mui-selected {
          background: $Color-Primary--85;
          .Chip-Parser-Tag {
            background: $Color-Primary-20;
            .MuiChip-label {
              color: $Color-White;
            }
          }
          .TableActions {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .MuiTableHead-root {
        .table-head-selected {
          display: flex;
          align-items: center;
          .selected-info {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $Color-Black;
            margin-right: 24px;
            min-width: 140px;
          }
          .selected-actions {
          }
        }
      }
      .MuiTableBody-root {
        .TableActions {
          opacity: 0.8;
        }

        .table-cell-filename {
          .filename-text-mode {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            &:hover {
              button {
                opacity: 1;
                pointer-events: all;
                transition: all 150ms ease-in-out;
              }
            }
            a {
              color: $Color-Black;
              font-weight: 500;
            }
            button {
              opacity: 0;
              pointer-events: none;
              transition: all 150ms ease-in-out;
            }
          }

          .filename-edit-mode {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 24px;
          }
        }

        .TableTags {
          display: flex;
          gap: 8px;
        }
      }
    }
  }
}

.TableUploadStatus {
  .status {
    display: flex;
    align-items: center;
    gap: 8px;
    .icon {
      display: flex;
      &.spinner {
        animation: animRotate 5s linear infinite;
      }
    }
  }
}

.Popover-CompanyTagList {
  .MuiPopover-paper {
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 0px 8px rgba($Color-Black, 0.1);
  }
  .CompanyTagList-AutoCompleted {
    width: 265px;
    background: $Color-White;
    /* height: 200px; */

    .filter-content {
      padding: 12px;
    }
  }
}

.Popover-FilterTagSearch {
  .MuiPopover-paper {
    width: 600px;
    background-color: transparent;
    box-shadow: none;
    padding: 20px;
    .PopoverBox {
      border-radius: 8px;
      padding: 12px 8px;
      box-shadow: 0px 0px 8px rgba($Color-Black, 0.1);
      background-color: $Color-White;
      .tag-list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        .tag-item {
        }
      }
    }
  }
}

.CreditStatusBox {
  display: flex;
  align-items: center;
  border-radius: 16px;
  height: 32px;
  padding: 4px 16px;
  cursor: pointer;

  &.status-has-credit {
    background: $Color-GrayBorder;
    .label {
      color: $Color-Navy;
    }
    .icon {
    }
    .action {
      border-left: 1px solid rgba($Black, 10%);
      .link {
        display: flex;
        align-items: center;
      }
      svg {
        path {
          fill: $Color-Navy;
        }
      }
    }
  }
  &.status-low-credit {
    background: $Color-Primary-20;
    .label {
      color: $Color-GrayBorder;
    }
    .icon {
    }
    .action {
      border-left: 1px solid rgba($Color-White, 10%);

      svg {
        path {
          fill: white;
        }
      }
    }
  }
  &.status-no-credit {
    background: $Color-Navy;
    .label {
      color: $Color-GrayBorder;
    }
    .icon {
    }
    .action {
      border-left: 1px solid rgba($Color-White, 10%);

      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .action {
    padding-left: 8px;
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
  .icon {
    margin-left: 6px;
    display: flex;
    align-items: center;
  }
}

.ParserDetail-Box {
  height: 100%;
  background: $Color-White;
  border-radius: 8px;
  border: 1px solid $Color-Primary--90;
  &.color-green {
    border: 1px solid var(--gray-300, #eef0f3);
    background: rgba($Color-SuccessTint, 0.3);
    .box-heading {
      .heading-left {
        .title {
          color: $Color-Success;
        }
      }
      .heading-right {
        .match-text {
          color: $Color-Success;
        }
      }
    }
  }
  &.color-red {
    border: 1px solid var(--gray-300, #eef0f3);
    background: rgba($Color-ErrorTint, 0.3);
    .box-heading {
      .heading-left {
        .title {
          color: $Color-Error;
        }
      }
      .heading-right {
        .match-text {
          color: $Color-Error;
        }
      }
    }
  }
  .box-heading {
    padding: 16px;
    border-bottom: 1px solid $Color-Primary--90;
    display: flex;
    justify-content: space-between;
    .heading-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $Color-Gray;
        margin-right: 12px;
      }
      .label {
        height: 20px;
        padding: 2px 10px;
        height: 24px;
        background: $Color-Primary--80;
        border-radius: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: $Color-Primary;
      }
    }
    .heading-right {
      display: flex;
      align-items: center;
      gap: 8px;
      .match-text {
        font-family: $Font-Secondary;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
      }
      .action {
        margin-left: auto;
        margin-top: -8px;
        margin-bottom: -8px;
      }
    }
  }
  .box-content {
    display: flex;
    padding: 16px;
  }
  &.direction-column {
    .box-content {
      flex-direction: column;
    }
  }
  &.direction-row {
    .box-content {
      flex-direction: row;
    }
  }
  &.gap-12 {
    .box-content {
      gap: 12px;
    }
  }
  &.gap-20 {
    .box-content {
      gap: 20px;
    }
  }
}

.ParserDetail-Heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  .Heading-Left {
    .name {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: $Color-Navy;
    }
    .job-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $Color-Black;
    }
  }
  .Heading-Right {
    .social-media {
      display: flex;
      gap: 8px;
      .item {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          svg {
            path {
              fill: $Color-Primary-20;
              @include transition-default;
            }
          }
        }
        svg {
          path {
            fill: $Color-Gray;
            @include transition-default;
          }
        }
      }
    }
  }
}

.ParserDetail-Summary {
  display: flex;
  justify-content: space-between;
  .Summary-Text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $Color-Gray;
  }
}

.BoxContent-Item-1 {
  display: flex;
  justify-content: space-between;
  .item-start {
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $Black;
      margin-bottom: 2px;
    }
    .desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $Color-Gray;
    }
  }
  .item-end {
    .date {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $Color-Gray;
    }
  }
}

.BoxContent-Item-2 {
  display: flex;
  justify-content: space-between;
  .item-start {
    display: flex;
    flex-direction: column;
    .department {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $Black;
      margin-bottom: 8px;
    }
    .university {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $Color-Gray;
    }
  }
  .item-end {
    display: flex;
    flex-direction: column;
    .degree {
      height: 20px;
      background: $Color-Primary--80;
      border-radius: 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $Color-Black;
      padding: 2px 10px;
      margin-bottom: 8px;
      text-align: center;
    }
    .year {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $Color-Gray;
      text-align: right;
    }
  }
}

.BoxContent-Item-ChipList {
  margin-bottom: 22px;
  &.type-pro {
    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 600;
      color: $Color-Black;
    }
    .list {
      filter: blur(4px);
      user-select: none;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  .title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $Color-Gray;
    margin-bottom: 8px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.BoxContent-Item-Contact {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 50%;
    margin-bottom: 12px;
    &.fullwidth {
      width: 100%;
    }
    .title {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $Color-Gray;
      margin-bottom: 4px;
    }
    .value {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $Black;
    }
  }
}

.DrawerContent-WorkExperience {
  .item {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $Color-Primary--90;
    .heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      gap: 8px;
      &-left {
        width: 65%;
        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: $Black;
        }
        .desc {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $Color-Gray;
        }
      }
      &-right {
        width: 35%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .date {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $Color-Gray;
          margin-bottom: 8;
        }
        .time {
          padding: 2px 10px;
          background: $Color-GrayBackground;
          border-radius: 10px;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          text-align: center;

          color: $Color-Navy;
        }
      }
    }
    .content {
      &-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $Color-Gray;
        margin-bottom: 4px;
      }
      &-detail {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $Black;
      }
      &.pro-content {
        position: relative;
        .content-detail {
          filter: blur(6px);
          user-select: none;
        }
        .pro-content-info {
          position: absolute;
          left: 50%;
          top: calc(50% - 10px);
          transform: translate(-50%, -50%);
          display: flex;
          gap: 6px;
          width: 100%;
          align-items: center;
          .info-text {
            padding: 6px;
            color: $Color-Black;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            border-radius: 8px;
            background: rgba($color: $Color-Gray, $alpha: 0.9);
            text-align: center;
            a {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

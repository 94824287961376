.Drawer-EvaluatorCandidateDetail {
  .Drawer-EvaluatorCandidateDetail-Header {
    padding: 24px;
    border-bottom: 1px solid $Color-Primary--90;
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $Color-Gray;
      margin-right: 12px;
    }
    .label {
    }
    .action {
      margin-left: auto;
    }
  }
  .Drawer-EvaluatorCandidateDetail-Content {
    padding: 24px 0;
    .candidate-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      padding: 0 24px;
      .info-left {
        display: flex;
        flex-direction: column;
        .name {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: $Black;
        }
        .email {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: $Black;
        }
        .date {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $Color-Gray;
        }
      }
      .info-right {
        .score {
          font-size: 24px;
          background-color: $Color-Primary--90;
          color: $Color-Primary;
          border-radius: 100%;
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
    }
    .candidate-results {
      display: flex;
      flex-direction: column;
      /* gap: 24px; */
      .result-item {
        padding: 24px;

        &:nth-child(2n + 1) {
          background-color: rgba($Color-Primary--90, 0.3);
        }
        .result-heading {
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          .number {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $Color-Black;
            margin-right: 12px;
          }
        }
        .result-text {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $Color-Black;
        }

        .result-detailed-reasoning {
          padding: 20px 24px;
          background-color: $Color-Primary--90;
          border-radius: 12px;
          margin-top: 24px;

          .heading {
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            gap: 10px;
            .icon {
              display: flex;
              align-items: center;
              svg {
                width: 24px;
                height: 24px;
                path {
                  fill: $Color-Primary-20;
                }
              }
            }
            .title {
              font-weight: 600;
              font-size: 18px;
              line-height: 24px;
              color: $Color-Navy;
            }
          }
          li {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $Color-Navy;
            margin-bottom: 8px;
            margin-left: 12px;
            padding-left: 4px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

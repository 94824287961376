.Page-ParserDetail {
  .Page-Heading {
    .top-action {
      margin-bottom: 0;
    }
  }
  .ParserDetail-Content {
    background: $Color-White;
    border: 1px solid $Color-Primary--90;
    border-radius: 8px;
    padding: 24px;

    .ParserDetail-Tabs {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $Color-GrayBorder;
      margin-bottom: 27px !important;
      .action {
        display: flex;
        gap: 12px;
        path {
          fill: $Color-Gray;
        }
      }
    }
    .Tabs-ParserDetail {
      width: 100%;
      .MuiTabs-scroller {
      }
      .ParserDetail-content-tab-action {
        margin-left: auto;
      }
    }
  }
}

.ParserDetail-List {
}

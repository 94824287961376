.Sidebar {
  overflow: hidden;

  .Sidebar-Content {
    border-right: 0;
    width: 80px;
    padding: 12px 8px;
    transition: all 150ms ease-in-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .Navigation,
    .Navigatation-Bottom {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .nav-item {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: white;
        display: flex;
        flex-direction: row;
        height: 56px;
        transition: all 150ms ease-in-out;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          width: 2px;
          height: 100%;
          right: -11px;
          top: 0;
          background: $Color-Primary-20;
          opacity: 0;
          transition: opacity 150ms ease-in-out;
        }
        &:hover {
          background: $Color-GrayBackground;
          transition: all 150ms ease-in-out;
          &:before {
            opacity: 1;
            transition: opacity 150ms ease-in-out;
          }
        }
        &.active,
        &:hover {
          background: $Color-GrayBackground;
          transition: all 150ms ease-in-out;
          &:before {
            opacity: 1;
          }
          .nav-icon {
            svg {
              path {
                fill: $Color-Primary-20;
              }
            }
          }
          .nav-label {
            color: $Color-Primary-20;
          }
        }
        &-inner {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 12px;
        }

        .nav-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .nav-label {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $Color-Gray;
        }
      }
    }
    .Sidebar-Header {
      border-radius: 8px;
      margin-bottom: 32px;
      .logo {
        user-select: none;
        &-full {
          display: flex;
          justify-content: center;
          padding: 16px;
          background: $Color-Black;

          img {
            width: 69px;
            user-select: none;
          }
        }
        &-small {
          display: flex;
          justify-content: center;
          padding: 12px;

          img {
            width: 32px;
            user-select: none;
          }
        }
      }
    }
    .Sidebar-Navigation {
    }
    .Sidebar-Footer {
      margin-top: auto;
      .Profile-Card {
        cursor: pointer;
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }
    }
  }
}

.MuiPopover-root {
  &.Menu-SidebarStandart {
    .MuiPaper-root {
      width: 130px;
      border: 1px solid $Color-GrayBackground;
      box-shadow: 0px 12px 16px -4px rgba($Color-GrayBorder, 0.08), 0px 4px 6px -2px rgba($Color-GrayBorder, 0.03);
      border-radius: 8px;
    }
    .Menu-SidebarStandart-List {
      padding-top: 0;
      padding-bottom: 0;

      li {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 14px;
        color: $Color-Black;
      }
    }
  }
}

.MuiTooltip-popper {
  &.Tooltip-SidebarNav {
    .MuiTooltip-tooltip {
      background: $Color-White;
      border: 1px solid $Color-GrayBackground;
      box-shadow: 0px 12px 16px -4px rgba($Color-GrayBorder, 0.08), 0px 4px 6px -2px rgba($Color-GrayBorder, 0.03);
      border-radius: 8px;
      padding: 10px 14px;
      .nav-item-tooltip-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .nav-item-tooltip-content-link {
          .nav-label {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $Color-Black;
            transition: color 200ms ease-in-out;
            &:hover {
              color: $Color-Primary-20;
              transition: color 200ms ease-in-out;
            }
          }
        }
      }
    }
  }
}

.Page-EvaluatorForm {
  background-color: $Color-GrayBackground;
  .Page-Header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }
  .Page-Content {
    padding-bottom: 32px;

    .Sidebar {
      background: $Color-White;
      border: 1px solid $Color-GrayBorder;
      border-radius: 16px;

      .heading {
        display: flex;
        align-items: center;
        padding: 32px 0;
        border-bottom: 1px solid $Color-GrayBorder;
        margin: 0 32px 32px 32px;
        .icon {
        }
        .title {
          font-weight: 600;
          font-size: 28px;
          line-height: 32px;
          color: $Color-Navy;
          padding-left: 24px;
        }
      }

      .info-list {
        padding: 0 32px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        .info-item {
          .info-heading {
            display: flex;
            align-items: center;
            .icon {
              width: 48px;
              height: 48px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: $Color-GrayBackground;
              border-radius: 12px;
              margin-right: 16px;
            }
            .title {
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              color: $Color-Navy;
            }
          }
          .text {
            padding-left: 64px;
            p {
              font-weight: 400;
              font-size: 12px;
              line-height: 24px;
              color: $Color-Gray;
              margin-bottom: 24px;
            }
          }
        }
      }

      .footer {
        padding: 32px;
      }
    }
    .Evaluator-Content {
      .Form-Candidate-Info {
        padding: 24px;
        background: $Color-White;
        border-radius: 16px;
        margin-bottom: 40px;
        border: 1px solid $Color-GrayBorder;
      }

      .Form-Answers {
        padding: 24px;
        background: $Color-White;
        border-radius: 16px;
        border: 1px solid $Color-GrayBorder;
        .form-item {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          .MuiFormControl-fullWidth {
            width: calc(100% - 54px);
          }
        }
        .action-delete {
          display: flex;
          align-items: flex-start;
          justify-content: center;
        }
        .Form-Actions {
          display: flex;
          justify-content: space-between;
          margin-top: 48px;
          padding-top: 48px;
          border-top: 1px solid $Color-GrayBorder;
        }
      }
    }
  }
}

.Page-Register {
  width: 392px;
  margin: 0 auto;
  .Register-Header {
    display: flex;
    margin-top: 40px;
    margin-bottom: 90px;
  }
  .Register-Form-Social {
    .Heading {
      margin-bottom: 44px;
      .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
        color: $Color-Black;
        margin-bottom: 12px;
      }
      .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $Black;
      }
    }
    .Social-Login {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .divider {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .Register-Form {
    .Heading {
      margin-bottom: 44px;
      .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
        color: $Color-Black;
        margin-bottom: 12px;
      }
      .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $Black;
      }
    }
  }
  .Register-Footer {
    margin-top: 20px;
    .text-terms {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $Color-Gray;
      margin-bottom: 32px;
      a {
        color: $Color-Black;
        font-weight: 600;
        text-decoration: underline;
      }
    }
    .redirect-login {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $Color-Black;
      a {
        color: $Color-Primary;
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
}

.Header {
  margin-left: calc(80px);
  width: calc(100% - 80px);
  box-sizing: content-box;
  background-color: transparent;
  background: $Color-GrayBackground;
  box-shadow: none;
  transition: all 150ms ease-in-out;
  &.background-gray {
    background: $Color-GrayBackground;
  }
  &.background-white {
    background: white;
  }
  &.sidebar-opened {
    margin-left: calc(220px + 24px);
    width: calc(100% - 220px);
    transition: all 150ms ease-in-out;
  }

  &-Content {
    display: flex;
    justify-content: space-between;
    min-height: 64px;
    /* min-height: 32px; */
    .Header-Start {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      /* height: 32px; */
      button {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .Header-End {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

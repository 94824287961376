.Drawer-ParserDetail {
  .Drawer-ParserDetail-Header {
    padding: 24px;
    border-bottom: 1px solid $Color-Primary--90;
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $Color-Gray;
      margin-right: 12px;
    }
    .label {
    }
    .action {
      margin-left: auto;
    }
  }
  .Drawer-ParserDetail-Content {
    padding: 24px;
  }
}

.Page-PaymentResult {
  .payment-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - (90px + 64px));
    padding-left: 20%;
    padding-right: 20%;
    .icon {
      margin-bottom: 24px;
      svg {
        width: 80px;
        height: 80px;
      }
    }
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: $Color-Navy;
      margin-bottom: 16px;
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $Color-Gray;
      margin-bottom: 16px;
      text-align: center;
    }
    .actions {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 24px;
    }
  }
}

.ReactTags__tags {
  .ReactTags__tagInput {
    margin-bottom: 8px;
    .ReactTags__tagInputField {
      height: 30px;
      background: $Color-White;
      border: 1px solid $Color-Primary--90;
      border-radius: 8px;
      padding: 7px 13px;
    }
  }
  .ReactTags__selected {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    .ReactTags__tag {
      -webkit-user-drag: none;
      display: flex;
      align-items: center;
      padding: 2px 8px;
      gap: 2px;
      height: 20px;
      background: $Color-Primary--90;
      border-radius: 16px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: $Black;
      margin-bottom: 4px;
      .ReactTags__remove {
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        padding: 0;
        padding-left: 7px;
        font-size: 16px;
        color: $Color-Gray;
        height: 20px;
        cursor: pointer;
      }
    }
  }
}

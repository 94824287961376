.Page-ProfileEdit {
  .ProfileEdit {
    .ProfileEdit-Form {
      padding: 24px;
      background: $Color-White;
      border: 1px solid $Color-Primary--90;
      border-radius: 8px;
      .MuiTelInput-TextField {
        width: 100%;
      }
      .profile-picture {
        .picture-avatar {
          width: 80px;
          height: 80px;
          position: relative;
          border-radius: 100%;
          cursor: pointer;
          .avatar-hover {
            @include transition-default;
          }
          &:hover {
            .MuiAvatar-root {
              filter: blur(4px);
            }
            .avatar-hover {
              opacity: 1;
              background: rgba($Color-Black, 0.6);
              @include transition-default;
            }
          }
          .avatar-hover {
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 80px;
            height: 80px;
            @include transition-default;
          }
        }
      }
      .heading-change-password {
        margin-top: 32px;
        margin-bottom: 0;
      }
      .form-input-password-group {
        position: relative;
        .button-passwordType {
          position: absolute;
          top: 26px;
          right: 14px;
        }
      }
      .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 64px;
        padding-top: 24px;
        border-top: 1px solid $Color-Primary--90;
        padding-left: 0;
        padding-right: 0;
        margin-left: 24px;
        margin-right: 0;
      }
    }
  }
}

.Password-Strenght {
  display: flex;
  align-items: center;
  margin-top: 8px;

  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: auto;
    margin-right: 8px;
    color: $Color-Gray;
  }
  .strenght-levels {
    display: flex;
    align-items: center;
    position: relative;
    gap: 6px;
    flex: 1;
    &.level-empty {
      .level {
        background: $Color-Primary--85;
        @include transition-default;
      }
    }
    &.level-1 {
      .level {
        &:nth-child(1) {
          background: $Color-Error;
          @include transition-default;
        }
      }
      .level-name {
        color: $Color-Error;
      }
    }
    &.level-2,
    &.level-3 {
      .level {
        &:nth-child(1),
        &:nth-child(2) {
          background: $Color-Warning;
          @include transition-default;
        }
      }
      .level-name {
        color: $Color-Warning;
      }
    }

    &.level-4 {
      .level {
        background: $Color-Success;
        @include transition-default;
      }

      .level-name {
        color: $Color-Success;
      }
    }

    .level {
      width: 33%;
      height: 6px;
      border-radius: 8px;
      background: $Color-Primary--85;
      @include transition-default;
    }

    .level-name {
      position: absolute;
      left: 0;
      bottom: -4px;
      transform: translateY(100%);
      margin-top: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.Page-HirizeFinList {
  .HirizeFinList {
    .HirizeFinList-List {
      padding: 24px;
      background: $Color-White;
      .HirizeFinList-Table-Header {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        .search-invoice {
          min-width: 345px;
          display: flex;
          align-items: center;
          gap: 16px;
          .search-input {
            width: 100%;
            position: relative;
            .delete-search {
              display: flex;
              position: absolute;
              right: 12px;
              top: 6px;
            }
          }
          .MuiTextField-root {
            width: 100%;
          }
        }
        .action-add-matcher {
        }
      }
      .HirizeFinList-Table {
        .table-head-selected {
          display: flex;
          align-items: center;
          .selected-info {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $Color-Black;
            margin-right: 24px;
            min-width: 140px;
          }
          .selected-actions {
          }
        }
        .table-row-create-match {
          display: flex;
          justify-content: center;
          svg {
            path {
              fill: $Color-Primary-20;
            }
          }
        }
      }
    }
  }
}

.MuiPopover-root {
  &.Menu-MatcherTypeList {
    .MuiPaper-root {
      border: 1px solid $Color-GrayBackground;
      box-shadow: 0px 12px 16px -4px rgba($Color-GrayBorder, 0.08), 0px 4px 6px -2px rgba($Color-GrayBorder, 0.03);
      border-radius: 8px;
    }
    .Menu-MatcherTypeList-List {
      padding-top: 0;
      padding-bottom: 0;

      li {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 14px;
        color: $Color-Black;
      }
    }
  }
}

.Popover-FilterBox {
  .MuiPopover-paper {
    border-radius: 8px;
  }
  .FilterBox {
    width: 265px;
    background: $Color-White;

    .filter-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      border-bottom: 1px solid $Color-Primary--90;
      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $Color-Navy;
      }
      .filter-priority {
        .MuiRating-root {
          gap: 2px;
        }
      }
    }
    .filter-content {
      padding: 12px;
    }
  }
}

.FilterBox-Tags {
  .tags {
    margin-bottom: 8px;
  }
  .suggested {
    .title {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $Color-Gray;
      margin-bottom: 8px;
    }
    .suggested-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      .MuiChip-root {
        margin-bottom: 4px;
      }
    }
  }
}

.FilterBox-Seniority {
}

.FilterBox-Priority {
  .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $Color-Gray;
    margin-bottom: 16px;
  }
  .slider {
    margin-bottom: 5px;
  }
  .slider-labels {
    display: flex;
    justify-content: space-between;
    .label {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $Black;
    }
  }
}

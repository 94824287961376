.Page-Login {
  width: 392px;
  margin: 0 auto;
  .Login-Header {
    display: flex;
    margin-top: 40px;
    margin-bottom: 90px;
  }
  .Login-Form-Social {
    .Heading {
      margin-bottom: 44px;
      .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
        color: $Color-Black;
        margin-bottom: 12px;
      }
      .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $Black;
      }
    }
    .Social-Login {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .divider {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .Login-Form {
    .Heading {
      margin-bottom: 44px;
      .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
        color: $Color-Black;
        margin-bottom: 12px;
      }
      .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $Black;
      }
    }
    .form-action-forgotPassword {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 12px;
      button {
        padding: 0;
        &:hover {
          background-color: transparent;
        }
      }
    }
    .form-action-send {
      margin-top: 44px;
    }
  }
  .Login-Footer {
    margin-top: 20px;

    .redirect-login {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $Color-Black;
      a {
        color: $Color-Primary;
        font-weight: 700;
      }
    }
  }
}

.Modal-CreateMatcher {
  .Modal-Box {
    width: 440px;
    padding: 24px;
    .Modal-Header {
    }
    .Modal-Content {
      .Tabs-CreateMatcher {
        margin-bottom: 20px;
        .MuiTabs-scroller {
          border-bottom: 1px solid $Color-GrayBorder;
        }
      }
      .Matcher-Name {
        margin-top: 12px;
        margin-bottom: 32px;
      }
      .Match-By-Filter {
        margin-top: 32px;
        margin-bottom: 32px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .filter-list {
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 100%;
          .filter-item {
            .filter-title {
              margin-bottom: 12px;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              color: $Color-Black;
            }
          }
        }
        /* .text {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: $Color-Black;
        }
        .select,
        .input {
          margin-left: 8px;
          margin-right: 8px;
          width: 140px;
        }
        .select-seniority {
          width: 75px;
        }
        .autocompleted {
          width: 100%;
        } */
      }
    }
    .Modal-Footer {
      display: flex;
      flex-direction: row;
      gap: 24px;
      .button-cancel {
        width: 128px;
      }
    }
  }
}

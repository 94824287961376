@font-face {
  font-family: 'eudoxus sans';
  src: url('../../assets/fonts/EudoxusSans/EudoxusSans-ExtraLight.woff2') format('woff2'),
    url('../../assets/fonts/EudoxusSans/EudoxusSans-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'eudoxus sans';
  src: url('../../assets/fonts/EudoxusSans/EudoxusSans-Light.woff2') format('woff2'),
    url('../../assets/fonts/EudoxusSans/EudoxusSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'eudoxus sans';
  src: url('../../assets/fonts/EudoxusSans/EudoxusSans-Regular.woff2') format('woff2'),
    url('../../assets/fonts/EudoxusSans/EudoxusSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'eudoxus sans';
  src: url('../../assets/fonts/EudoxusSans/EudoxusSans-Medium.woff2') format('woff2'),
    url('../../assets/fonts/EudoxusSans/EudoxusSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'eudoxus sans';
  src: url('../../assets/fonts/EudoxusSans/EudoxusSans-Bold.woff2') format('woff2'),
    url('../../assets/fonts/EudoxusSans/EudoxusSans-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'eudoxus sans';
  src: url('../../assets/fonts/EudoxusSans/EudoxusSans-Bold.woff2') format('woff2'),
    url('../../assets/fonts/EudoxusSans/EudoxusSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'eudoxus sans';
  src: url('../../assets/fonts/EudoxusSans/EudoxusSans-ExtraBold.woff2') format('woff2'),
    url('../../assets/fonts/EudoxusSans/EudoxusSans-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: block;
}

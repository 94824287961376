.MuiAvatar-root {
  background: $Color-PurpleTint;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $Color-Purple;
}

.Page-EvaluatorList {
  .Page-Heading {
    align-items: flex-end;
    .title {
      margin-bottom: 12px;
      .evaluator-name {
        color: $Color-Primary-20;
      }
    }
    .detail {
      .button-link {
        margin-left: 12px;
      }
      .evaluator-time {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: $Color-Gray;
        padding-top: 6px;
        padding-left: 0;
      }
    }
  }

  .EvaluatorList {
    .EvaluatorList-List {
      padding: 24px;
      background: $Color-White;
      .EvaluatorList-Table-Header {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        .EvaluatorList-Table-Header-Left {
          display: flex;
          .search-candidate {
            min-width: 345px;
            .MuiTextField-root {
              width: 100%;
            }
          }
        }
        .EvaluatorList-Table-Header-Right {
          display: flex;
          .select-evaluator {
            display: flex;
            gap: 24px;
            align-items: center;
          }
        }
      }
      .EvaluatorList-Table {
        .table-row-create-match {
          display: flex;
          justify-content: center;
          svg {
            path {
              fill: $Color-Primary-20;
            }
          }
        }
        .table-cell-action {
          svg {
            path {
              fill: $Color-Gray;
            }
          }
        }
      }
    }
  }
}

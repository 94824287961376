.Page-BillingInvoices {
  .BillingInvoices {
    .BillingInvoices-Box {
      padding: 24px;
      background: $Color-White;
      border: 1px solid $Color-Primary--90;
      border-radius: 8px;
      margin-bottom: 40px;
      .payment {
        .payment-info-list {
        }
      }
      .payment-method {
        margin-top: 64px;
        .payment-method-list {
          .add-credit-card {
            background: rgba($Color-White, 0.3);
            border: 1px dashed $Color-GrayBorder;
            border-radius: 8px;
            min-height: 124px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .icon {
              margin-right: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                width: 24px;
                height: 24px;
                path {
                  fill: $Color-Gray;
                }
              }
            }
            .text {
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: $Color-Gray;
            }
          }
        }
      }
      .action-auto-renev {
        margin-top: 44px;
      }
      .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 64px;
        padding-top: 24px;
        border-top: 1px solid $Color-Primary--90;
        padding-left: 0;
        padding-right: 0;
        margin-left: 24px;
        margin-right: 0;
      }
    }
  }
}

.CardPaymentMethod,
.CardPaymentPlanDetail {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: $Color-White;
  border: 1px solid $Color-GrayBorder;
  border-radius: 12px;
  .Card-Header {
    margin-bottom: 24px;
    .Header-Top {
      display: flex;
      align-items: center;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        background-color: $Color-White;
        width: 32px;
        height: 32px;
        background: $Color-Primary--90;
        border-radius: 100%;
      }
      .title {
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: $Color-Navy;
      }
      .payment-status {
        margin-left: auto;
      }
    }
    .Header-Bottom {
      margin-left: 44px;
      margin-top: 4px;
      .credit-short-detail {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        .price {
          color: $Color-Gray;
        }

        .credit {
          color: $Color-Primary-20;
        }
      }
    }
  }
  .Card-Body {
    margin-bottom: 24px;
  }
  .Card-Footer {
    margin-top: auto;
  }
}

.CardPaymentPlanDetail {
  .Card-Body {
    .payment-detail-list {
      display: flex;
      flex-direction: column;
      .list-item {
        display: flex;
        justify-content: space-between;
        padding: 12px 0;
        border-bottom: 1px dashed $Color-GrayBorder;
        &:last-child {
          border-bottom: none;
        }
        .text {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: $Color-Gray;
        }
        .value {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: right;
          color: $Color-Navy;
          display: flex;
          align-items: center;
          gap: 12px;
          .progress {
            width: 90px;
          }
        }
      }
    }
  }
}

.CardPaymentMethod {
  height: 100%;
  .Card-Body {
    .text {
      padding: 80px 0;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .no-credit-card {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: $Color-Gray;
    }
    .payment-method-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .list-item {
      }
    }
  }
}

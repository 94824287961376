$Gradient-Primary-16: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%), #3a5aff;
@mixin Gradient-Primary-12 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), #3a5aff;
}
@mixin Gradient-Primary-16 {
  background: $Gradient-Primary-16;
}
@mixin Gradient-Secondary-12 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), #82ff57;
}
@mixin Gradient-Secondary-16 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%), #82ff57;
}
@mixin Gradient-Ocean {
  background: linear-gradient(130deg, #3a5aff 0%, #33e7ff 100%);
}
@mixin Gradient-Error {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), #df1c3f;
}
@mixin Gradient-Error-Hover {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%), #df1c3f;
}
@mixin Gradient-Black {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%);
}
@mixin Gradient-White-Button {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(164, 172, 185, 0.02) 100%), #fff;
}
@mixin Gradient-White-Button-Hover {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(164, 172, 185, 0.08) 100%), #fff;
}
@mixin Gradient-White-Button-Pressed {
  background: linear-gradient(180deg, rgba(164, 172, 185, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), #fff;
}

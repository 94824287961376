.Page-EndPoint {
  .EndPoint {
    .EndPoint-Form {
      padding: 24px;
      background: $Color-White;
      .key-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 12px;
      }
      .action {
      }
      .form-footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 64px;
        padding-top: 24px;
        border-top: 1px solid $Color-Primary--90;
        padding-left: 0;
        padding-right: 0;
        margin-left: 24px;
        margin-right: 24px;
      }
    }
  }
}

.KeyField {
  background: $Color-White;
  border: 1px solid $Color-Primary--90;
  box-shadow: 0px 1px 2px rgba($Black, 0.05);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  height: 44px;
  .key {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $Black;

    &.key-deactive {
      color: $Color-Gray;
      opacity: 0.5;
    }
    &.key-active {
    }
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 12px;
    .action-copy {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        top: 14px;
        left: -8px;
        width: 1px;
        height: 12px;
        background: $Color-GrayBorder;
      }
    }
  }
}

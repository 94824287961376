.Modal-MatcherSelectFile {
  .Modal-Box {
    width: 480px;
    padding: 24px;

    .Modal-Header {
    }
    .Modal-Content {
      .Tabs-MatcherSelectFile {
        margin-bottom: 20px;
        .MuiTabs-scroller {
          border-bottom: 1px solid $Color-GrayBorder;
        }
      }
    }
    .Modal-Footer {
    }
  }
}

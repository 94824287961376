.SnackbarContainer-root {
  .MuiCollapse-root {
    .MuiCollapse-wrapper {
      .MuiCollapse-wrapperInner {
        .SnackbarItem-wrappedRoot {
          .SnackbarContent-root {
            background: $Color-Primary;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 2px 2px 25px rgba($Black, 0.1);
            .notistack-snackbar {
              font-weight: 300;
              font-size: 18px;
              line-height: 30px;
              color: $Color-White;
              color: $Color-Navy;
            }
            &.SnackbarItem-variantDefault {
              background: $Color-Primary;
            }
            &.SnackbarItem-variantError {
              background: $Color-Error;
            }
            &.SnackbarItem-variantSuccess {
              background: $Color-Success;
            }
            &.SnackbarItem-variantWarning {
              background: $Color-Primary;
            }
            &.SnackbarItem-variantInfo {
              background: $Color-Primary;
            }
          }
        }
      }
    }
  }
}

.Card-ProductCheckout {
  display: flex;
  flex-direction: column;
  background: $Color-White;
  outline: 1px solid $Color-Primary--90;
  border-radius: 8px;
  min-height: 244px;
  &.is-selected {
    outline-color: $Color-Primary;
    outline-width: 2px;
  }
  .Card-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;

    .product-icon {
      img {
        height: 44px;
        width: 44px;
      }
    }
    .product-toggle {
      margin-top: -9px;
      margin-right: -9px;
    }
  }
  .Card-Content {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;

    .product-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $Black;
      margin-bottom: 4px;
    }
    .product-desc {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $Black;
      margin-bottom: 24px;
      height: 32px;
    }
    .product-document-select {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      .select {
        flex: 1;
        .MuiInputBase-root {
          width: 100%;
        }
      }
      .select-label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $Black;
      }
    }
  }
  .Card-Footer {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-top: 1px solid $Color-Primary--90;
    .feature-action {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    .credit-info {
      .credit-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $Color-Gray;
      }
      .credit-value {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $Black;
      }
    }
  }
}

.MenuItem-Document-Checkout {
  .label {
    width: 40px;
  }
}

.MuiTextField-root.theme-dark {
  .MuiInputBase-root {
    &.Mui-error {
      & > .MuiInputBase-input {
        &:placeholder-shown,
        &::placeholder {
          color: $Color-WarningTint;
        }
      }
    }
  }
  .MuiOutlinedInput-root {
    background: rgba($Color-White, 0.1);
    border: 1px solid rgba($Color-White, 0.2);
    border-radius: 8px;

    .MuiInputBase-input {
      color: $Color-White;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      &:placeholder-shown,
      &::placeholder {
        color: $Color-White;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
      box-shadow: none;
      legend {
        span {
          display: none;
        }
      }
    }
  }
}

.MuiTextField-root {
  .MuiInputBase-root {
    background: $Color-White;
    border-radius: 6px;
    padding: 0;

    &.Mui-error {
      /* background: url('../../public/icons/input-error.svg'); */
      background-repeat: no-repeat;
      background-position-x: calc(100% - 12px);
      background-position-y: 16px;
      & > .MuiInputBase-input {
        &:placeholder-shown,
        &::placeholder {
          color: $Color-Error;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $Color-WarningTint;
      }
    }
    & > .MuiInputBase-input {
      &.MuiOutlinedInput-input {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        height: 44px;
        box-sizing: border-box;
        padding: 13px 16px;
        color: $Color-Navy;
        &:placeholder-shown,
        &::placeholder {
          color: $Color-Gray;
        }
      }
    }
    .MuiInputAdornment-root {
      margin-left: 13px;
      margin-right: 0;
    }
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $Color-Primary;
        box-shadow: 0px 1px 2px rgba($Black, 0.05), 0px 0px 0px 1px $Color-Blue;
      }
    }
    &.MuiInputBase-adornedStart {
      .MuiInputBase-input {
        padding-left: 8px;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid $Color-Primary--90;
      box-shadow: 0px 1px 2px rgba($Black, 0.05);
    }
  }

  &.type-inline {
    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 0;
        height: 24px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        padding-bottom: 4px;
        color: $Color-Primary-20;
        &:placeholder-shown,
        &::placeholder {
          font-weight: 400;

          color: $Color-Gray;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
        border-bottom: 1px solid $Color-Primary--90;
        box-shadow: none;
      }
    }
  }
}

.MuiTextField-root {
  .MuiInputLabel-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-left: 6px;
    margin-bottom: 0;
    top: -3px;
    padding-left: 0;
    &.MuiInputLabel-shrink {
      color: $Color-Primary;
      top: 0;
    }
  }
  .MuiFilledInput-root {
    height: 50px;
    border: none;
    outline: 1px solid $Color-GrayBackground;

    .MuiInputBase-input {
      padding-bottom: 6px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    &.Mui-focused {
      outline-color: $Color-Primary;
    }
    &:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
    &:after {
      display: none;
    }
    &:before {
      border-bottom: none;
    }
  }
}

.Page-CompanyAccount {
  .CompanyAccount {
    .CompanyAccount-Form {
      padding: 24px;
      background: $Color-White;
      border: 1px solid $Color-Primary--90;
      border-radius: 8px;

      .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 64px;
        padding-top: 24px;
        border-top: 1px solid $Color-Primary--90;
        padding-left: 0;
        padding-right: 0;
        margin-left: 24px;
        margin-right: 0;
      }
    }
  }
}

.Layout-Password {
  height: 100vh;
  background-color: $Color-Navy;
  .LayoutPassword {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-image: url('../../../../public/images/bg-forgot-password.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    &-Header {
      display: flex;
      justify-content: center;
      padding-top: 56px;
      .logo {
        img {
          width: 100px;
        }
      }
    }
    &-Content {
      display: flex;
      justify-content: center;
    }
    &-Footer {
      padding-bottom: 44px;
      .copyright {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $Color-Primary--90;
      }
    }
  }
}

.LayoutPassword-Content {
  .Content-Card {
    background: $Color-White;
    border-radius: 8px;
    width: 600px;
    .ForgotPassword-EmailForm,
    .ForgotPassword-ResetPassword {
      padding: 44px;

      .heading {
        margin-bottom: 44px;

        .title {
          font-weight: 700;
          font-size: 36px;
          line-height: 40px;
          color: $Color-Navy;
          margin-bottom: 16px;
        }
        .desc {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $Color-Gray;
        }
      }
    }

    .ForgotPassword-CodeVerification {
      display: flex;
      flex-direction: column;
      padding: 64px 70px;
      .heading {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 44px;
        .title {
          font-weight: 700;
          font-size: 36px;
          line-height: 40px;
          text-align: center;
          color: $Color-Navy;
        }
        .desc {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;

          color: $Color-Gray;
          .email {
            font-weight: 600;
            color: $Color-Primary;
          }
        }
      }

      .form-action {
      }
      .form-footer {
        margin-top: 44px;
        .resend-code {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: $Color-Black;
          a {
            color: $Color-Primary;
            font-weight: 700;
            text-decoration: underline;
          }
        }
      }
    }

    .CreatePassword-NewPassword {
      padding: 44px;

      .heading {
        margin-bottom: 32px;
        .icon {
          margin-bottom: 32px;
          img {
            width: 56px;
          }
        }
        .title {
          font-weight: 700;
          font-size: 30px;
          line-height: 36px;
          color: $Color-Navy;
        }
      }
      form {
        .input-label {
          margin-bottom: 16px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $Black;
        }
      }
    }

    .CreatePassword-Result {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 64px 44px;
      .icon {
        margin-bottom: 36px;
        display: flex;
        justify-content: center;
      }

      .status-title {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 8px;
        .text {
          font-weight: 700;
          font-size: 36px;
          line-height: 40px;
          text-align: center;
          color: $Color-Navy;
        }
        .status-icon {
          display: flex;
          align-items: center;
          svg {
            width: 44px;
            height: 44px;
            path {
              stroke: $Color-Success;
            }
          }
        }
      }
      .status-desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $Black;
        text-align: center;
      }
      .info-action {
        margin-top: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        .loading-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 14px;
          }
        }
        .text {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $Color-Gray;
        }
      }
    }
  }
}

.Input-CodeArea {
  .form-inputs {
    display: flex;
    justify-content: center;
    gap: 22px;
    margin-bottom: 44px;
    .MuiInputBase-root {
      width: 80px;
      height: 80px;
      input {
        padding: 0;
        height: 80px;
        text-align: center;
        font-size: 32px;
      }
    }
  }
}

.Page-AccessManagement {
  .AccessManagement {
    .AccessManagement-Form {
      padding: 24px;
      background: $Color-White;
      border: 1px solid $Color-Primary--90;
      border-radius: 8px;
      margin-bottom: 40px;

      .active-user-list {
      }
      .pending-user-list {
        margin-top: 64px;
      }
      .user-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .user-item {
          border-bottom: 1px solid $Color-Primary--90;
          padding-bottom: 16px;
          padding-top: 16px;
          &:last-child {
            border-bottom: none;
          }
          .Card-User {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .card-left {
              .card-profile {
                display: flex;
                align-items: center;
                .profile-avatar {
                  margin-right: 16px;
                }
                .profile-info {
                  display: flex;
                  flex-direction: column;
                  .profile-name {
                    display: flex;
                    gap: 8px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: $Black;
                  }
                  .profile-email {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: $Color-Gray;
                  }
                }
              }
            }
            .card-right {
              .actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.Page-HirizeIQ {
  .HirizeIQ-Content {
  }
  .HirizeIQ-Generate {
    .HirizeIQ-Generate-Box {
      background: $Color-White;
      border-left: 1px solid $Color-Primary--90;
      border-radius: 8px;

      .heading {
        padding: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $Color-Primary--90;

        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: $Color-Black;
        }
        .heading-action {
        }
      }
      .generate-form {
        padding: 24px;
        .form-item {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
          .form-title {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $Color-Black;
            margin-bottom: 10px;
          }
          .job-description {
            .MuiTextField-root {
              width: 100%;
            }
          }
          .job-description-length-counter {
            display: flex;
            justify-content: flex-end;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            margin-top: 6px;
            &.limit-exceed {
              .length-current {
                color: $Color-Error;
                font-weight: 500;
              }
              .length-limit {
                color: $Color-Error;
                font-weight: 500;
              }
            }

            .length-current {
              color: $Color-Primary-20;
            }
            .length-limit {
              color: $Color-Gray;
            }
          }
        }
      }
      .action {
        padding: 24px;
        padding-top: 0;
      }
    }
  }
  .HirizeIQ-Result {
    .HirizeIQ-Result-Box {
      background: $Color-White;
      border-left: 1px solid $Color-Primary--90;
      border-radius: 8px;
      padding: 24px;
      height: 100%;
      .heading {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $Color-GrayBorder;
        padding-bottom: 0;
        padding: 0;
        .HirizeIQ-Result-Tabs {
        }
        .result-actions {
          display: flex;
          align-items: center;
          gap: 16px;
          svg {
            path {
              fill: $Color-Gray;
            }
          }
        }
      }

      .HirizeIQ-NewOutputs {
        margin-top: 24px;

        .NoResult {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 110px 55px;
          gap: 32px;
          .icon {
          }
          .text {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: $Color-Gray;
          }
        }
      }

      .HirizeIQ-History {
        .history-item {
          background-color: $Color-White;
          width: calc(100% + 48px);
          margin-left: -24px;

          padding: 16px 24px;
          &:nth-child(2n) {
            background: $Color-GrayBackground;
          }
        }
      }
    }
  }
}

.HirizeIQ-NewOutputs,
.HirizeIQ-History {
  &.pdf-state {
    width: 210mm;
    height: 297mm;
    padding: 10mm;
    padding-bottom: 0;
    margin: 0;
    background-color: $Color-White;
    background-repeat: no-repeat;
    background-image: url('../../../public/images/logo.png');
    background-position-x: center;
    background-position-y: 96%;
    background-size: 200px;
    .ResultBox {
      .summary-note,
      .interview-questions {
        .title {
          font-size: 24px;
          line-height: 1.5;
          margin-bottom: 22px;
        }
      }
      .summary-note {
        margin-bottom: 64px;
        .text {
          p {
            font-size: 14px;
            line-height: 1.4;
            break-before: always;
            break-after: always;
          }
        }
      }
      .interview-questions {
        .questions {
          li {
            font-size: 14px;
            line-height: 1.4;
            margin-bottom: 24px;
            break-before: always;
            break-after: always;
          }
        }
      }
    }
  }
  .ResultBox {
    .date {
      text-align: right;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $Color-Gray;
      padding-top: 16px;
      padding-bottom: 10px;
    }
    .summary-note,
    .interview-questions {
      .title {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $Black;
        margin-bottom: 4px;
      }
    }
    .summary-note {
      margin-bottom: 24px;
      .text {
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $Black;
        }
      }
    }
    .interview-questions {
      .questions {
        padding-left: 14px;
        li {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $Black;
          list-style: decimal;
          margin-bottom: 4px;
        }
      }
    }
  }
}

.HirizeIQ-History {
  &.pdf-state {
    height: auto;
  }
}

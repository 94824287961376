.Banner-FixedPayment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 24px;
  height: 56px;
  position: relative;
  &.type-info {
    background: $Color-Navy;
    .background {
      svg {
        rect {
          stroke: $Color-White;
        }
      }
    }
    .icon {
      background: rgba($Color-White, 0.1);
    }
    .text {
      color: $Color-White;
    }
  }
  &.type-warning {
    background: $Color-WarningTint;
    .background {
      svg {
        rect {
          stroke: $Color-Warning;
        }
      }
    }
    .icon {
      background: rgba($Color-Warning, 0.1);
    }
    .text {
      color: $Color-Warning-40;
    }
  }

  &.type-alert {
    background: $Color-WarningTint;
    .background {
      svg {
        rect {
          stroke: $Color-Error;
        }
      }
    }
    .icon {
      background: rgba($Color-Error, 0.1);
    }
    .text {
      color: $Color-Error;
    }
  }
  .background {
    position: absolute;
    right: 0;
    top: 0;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 28px;
    border-radius: 999px;
    margin-right: 8px;
  }
  .text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .action {
    margin-left: auto;
  }
}

.Card-PricingSummaryProduct {
  &.type-normal {
  }
  &.type-bordered {
    background: $Color-White;

    border: 1px solid $Color-Primary--90;
    border-radius: 8px;
    padding: 16px;
  }
  display: flex;
  justify-content: space-between;
  .product-info {
    display: flex;
    flex-direction: column;
    .product-name {
      margin-bottom: 2px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $Color-Navy;
    }
    .product-desc {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $Color-Gray;
    }
  }
  .pricing-info {
    display: flex;
    flex-direction: column;
    .product-price {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $Color-Navy;
      margin-bottom: 2px;
      text-align: right;
    }
    .product-credit {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $Color-Gray;
    }
  }
}

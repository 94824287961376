.Card-Product {
  display: flex;
  flex-direction: column;
  background: $Color-White;
  outline: 2px solid $Color-Primary--90;
  border-radius: 8px;
  padding: 24px 16px;
  min-height: 244px;
  justify-content: space-between;
  @include transition-default;

  &:hover {
    outline-color: $Color-Primary;
    outline-width: 2px;
    @include transition-default;
  }
  .Card-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    .product-icon {
      width: 44px;
      height: 44px;
      img {
        width: 44px;
      }
    }
    .product-link {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
  .Card-Content {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    .product-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $Black;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .product-desc {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $Black;
      height: 32px;
    }
  }
  .Card-Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    &-Left {
      .change-plan {
        margin-bottom: -7px;
        margin-right: -12px;
        .MuiFormControlLabel-root {
          margin-right: 0;
        }
      }
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: $Font_Primary;
}

::selection {
  background: black;
  color: $Color-White;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: $Font_Primary;
  font-size: 16px;
  color: black;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  overflow-x: hidden;
  margin: 0;
}

img {
  width: 100%;
}

a,
a:hover {
  text-decoration: none;
}

ul,
ul > li {
  list-style: none;
}

p,
ul,
ol,
ul > li {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.body-lock {
  overflow: hidden;
}

.Page-UIKIT {
  .list {
    display: flex;
    gap: 10px;
    padding: 20px;
    background-color: $Color-Gray;
  }
}

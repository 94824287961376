.Page-Heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  position: sticky;
  top: 0;
  z-index: 999;
  background: $Color-GrayBackground;
  padding-bottom: 12px;
  margin-bottom: 20px;
  .Heading-Start {
    .top-action {
      margin-bottom: 32px;
      padding-top: 4px;
    }
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: $Color-Navy;
    }
    .desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $Color-Gray;
      a {
        color: $Color-Primary;
        text-decoration: underline;
      }
    }
  }
  .Heading-End {
    display: flex;
    align-items: flex-end;
    gap: 20px;
  }
}

.Page-MatcherDetail {
  .MatcherDetail {
    .Page-Heading {
      .top-action {
        margin-bottom: 0;
      }
    }
    .MatcherDetail-List {
      padding: 30px 24px;
      background: $Color-White;
      .MatcherDetail-Table-Header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $Color-Primary--90;
        flex-wrap: wrap;
        .action-add-resumes {
          flex: 50%;
          display: flex;
          justify-content: flex-end;
          button {
            font-weight: 500;
          }
        }
        .filter-list {
          flex: 50%;
          display: flex;
          gap: 12px;
          .filter-item {
            .MuiButton-outlined {
              font-size: 12px;
              line-height: 16px;
            }
            .MuiButton-text {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
        .filter-result {
          flex: 100%;
          display: flex;
          gap: 8px;
          margin-top: 16px;
        }
      }
      .MatcherDetail-Table {
        .MuiTableContainer-root {
          max-height: fit-content;
        }
        .row-status-opened {
          background-color: $Color-Primary--90;
        }
        .button-toggle-detail {
          svg {
            transform: rotate(180deg);
            @include transition-default;
          }
          &.opened {
            svg {
              transform: rotate(0deg);
              @include transition-default;
            }
          }
        }
        .Row-Opened {
          & > .MuiTableCell-root {
            padding: 0;
          }
          .Row-Opened-Detail {
            padding: 16px 24px;
            background: $Color-GrayBackground;
            width: 100%;
          }
          .opened-detail-action {
            display: flex;
            justify-content: center;
            margin-top: 32px;
            .MuiButton-root {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: $Color-Gray;
              svg {
                path {
                  fill: $Color-Navy;
                }
              }
            }
          }
        }

        .table-cell-status-icon {
          padding-left: 12px;
          .status {
            display: flex;
            width: 12px;
            height: 12px;
            flex-shrink: 0;
            border-radius: 100%;
            &.status-positive {
              background-color: $Color-Success;
            }
            &.status-negative {
              background-color: $Color-Error;
            }
            &.status-not-required {
              background-color: $Color-Yellow;
            }
          }
        }

        .TableScore {
          display: flex;
          align-items: center;
          gap: 6px;
          .icon {
            display: flex;
            align-items: center;
            svg {
              width: 20px;
            }
          }
          .score {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */

            display: flex;
            align-items: center;

            color: $Color-Black;
          }
        }
      }
    }
  }
}

.MuiPopover-root {
  &.Menu-MatcherTypeList {
    .MuiPaper-root {
      border: 1px solid $Color-GrayBackground;
      box-shadow: 0px 12px 16px -4px rgba($Color-GrayBorder, 0.08), 0px 4px 6px -2px rgba($Color-GrayBorder, 0.03);
      border-radius: 8px;
    }
    .Menu-MatcherTypeList-List {
      padding-top: 0;
      padding-bottom: 0;

      li {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 14px;
        color: $Color-Black;
      }
    }
  }
}

.Popover-FilterBox {
  .MuiPopover-paper {
    border-radius: 8px;
  }
  .FilterBox {
    width: 265px;
    background: $Color-White;

    .filter-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      border-bottom: 1px solid $Color-Primary--90;
      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $Color-Navy;
      }
      .filter-priority {
        .MuiRating-root {
          gap: 2px;
        }
      }
    }
    .filter-content {
      padding: 12px;
    }
  }
}

.FilterBox-Tags {
  .tags {
    margin-bottom: 8px;
  }
  .suggested {
    .title {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $Color-Gray;
      margin-bottom: 8px;
    }
    .suggested-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      .MuiChip-root {
        margin-bottom: 4px;
      }
    }
  }
}

.FilterBox-Seniority {
}

.FilterBox-Priority {
  .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $Color-Gray;
    margin-bottom: 16px;
  }
  .slider {
    margin-bottom: 5px;
  }
  .slider-labels {
    display: flex;
    justify-content: space-between;
    .label {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $Black;
    }
  }
}

.Page-ParserSettings {
  .ParserSettings-Content {
    background: $Color-White;
    border: 1px solid $Color-Primary--90;
    border-radius: 8px;
    padding: 24px;
    .ParserSettings-Tabs {
      display: flex;
      justify-content: space-between;
    }
    .Tabs-ParserSettings {
      width: 100%;
      .MuiTabs-scroller {
        border-bottom: 1px solid $Color-GrayBorder;
        margin-bottom: 27px !important;
      }
      .ParserSettings-content-tab-action {
        margin-left: auto;
      }
    }
  }
  .ParserSettings-List {
    .info-line {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #818898;
      margin-bottom: 64px;
    }
    .list-section-item {
      margin-bottom: 64px;
      &:last-child {
        margin-bottom: 0;
      }
      .list-section-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: $Color-Navy;
        margin-bottom: 32px;
      }
      .config-item {
        display: flex;
        align-items: center;
        gap: 10px;
        .config-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $Color-Gray;
        }
        .config-info {
        }
        .config-switch {
          display: flex;
          gap: 32px;
          margin-left: auto;
          &.config-switch-model {
            .MuiFormGroup-root {
              background: rgba($color: $Color-Primary--85, $alpha: 0.75);
              padding: 4px;
              border-radius: 16px;
            }
          }
        }
      }
    }
  }
}

.Page-ZapierLogin {
  height: 100vh;
  background: $Color-Primary-20;
  display: flex;
  align-items: center;
  justify-content: center;
  .ZapierLogin {
    width: 800px;
    .Login-Form {
      .Heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-bottom: 24px;
        .title {
          font-size: 34px;
          margin-bottom: 12px;
          color: $Color-Primary--80;
        }
        .desc {
          font-size: 22px;
        }
      }
    }
  }
}

.Modal-AddJobParser {
  .Modal-Box {
    width: 480px;
    padding: 24px;

    .Modal-Header {
    }
    .Modal-Content {
    }
    .Modal-Footer {
    }
  }
}

.MuiPopover-root {
  &.Menu-QuickMenu {
    .MuiPaper-root {
      border: 1px solid $Color-GrayBackground;
      box-shadow: 0px 12px 16px -4px rgba($Color-GrayBorder, 0.08), 0px 4px 6px -2px rgba($Color-GrayBorder, 0.03);
      border-radius: 8px;
    }
    .Menu-QuickMenu-List {
      padding-top: 0;
      padding-bottom: 0;

      li {
        padding: 10px 14px;
        font-size: 12px;
        line-height: 16px;
        color: $Color-Black;
      }
    }
  }
}
